body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
}
a {
  text-decoration: none;
}
a{
  color: #233469;
  transition: all 0.2s ease-in-out;
}
a:hover {
  color: #000;
  transition: all 0.2s ease-in-out;
}
img {
  width: 100%;
}
h1,h2,h3,h4 {
  margin: 0;
  padding: 0;
}
p {
  line-height: 1.3;
}

input::placeholder, textarea::placeholder {
  color: #999;
  font-size: 14px;
  font-family: 'Montserrat';
}
.clear {
  clear: both;
}
#logo {
  width: 200px;
  float: left;
}
#header {
  background-color:#fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 4;
}
#header_content {
  max-width: 1370px;
  margin: 0 auto;
}
#top {
  display: none;
  width: 100%;
  height: 900px;
  position: relative;
  background-image: url("/public/img/puerto.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
nav {
  float: right;
}
nav ul {
  padding: 0;
  margin: 0;
}
nav ul li {
  display: inline-block;
  font-weight: 600;
  padding: 32px 20px;
  font-size: 13px;
}
nav ul li:last-child {
 padding-right: 0;
}
nav ul li a{
  text-transform: uppercase;
  color: #000;
  transition: all 0.2s ease-in-out;
}
nav ul li a:hover {
  color: #233469;
  border-bottom: solid 1px #233469;
  padding-bottom: 5px;
  transition: all 0.2s ease-in-out;
}
.slide_text {
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  text-shadow: 0px 0px 1px #000;
  background-color: rgba(0, 0, 0, 0.3);
}
.slide_text h1{
  font-size: 40px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slide_text p {
  font-size: 58px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#nosotros {
  position: relative;
}
#nosotros .content_left {
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  width: 50%;
  height: 700px;
}
#nosotros .content_right {
  background: #ffff;
  position: absolute;
  width: 40%;
  padding: 60px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
#nosotros h2 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}
#nosotros h2 span{
 font-weight: 600;
}
#nosotros p {
  font-size: 14px;
}
#servicios {
  max-width: 1370px;
  margin: 100px auto;
  text-align: center;
}
#servicios .titulo h2 {
  color: #233469;
  text-align: center;
  font-size: 42px;
  font-weight: 400;
}
#servicios .titulo h2 span {
  font-weight: 600;
}
#servicios .box {
  width: 29%;
  margin: 2%;
  float: left;
  text-align: left;
}
#servicios .box_content {
  padding: 40px;
  border: solid 10px #f7f7f7;
  height: 280px;
}
#servicios .box_content img{
  width: 100px;
}
#servicios .box_content h2{
  font-size: 22px;
}
.content_servicios .content_left img{
  width: 50px;
  height: auto;
  float: left;
}
.content_servicios .content_right ul li img{
  width: 50px;
  height: auto;
  float: left;
}
.content_servicios .content_left ul li span, .content_servicios .content_right ul li span {
  float: left;
  display: inline-block;
  position: relative;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  color: #233469;
  font-weight: 600;
}
#video {
  width: 100%;
  height: 55.6vw;
  position: relative;
}
iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.video_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.ver_todos {
  font-size: 20px;
  font-weight: 600;
}
#footer {
  width: 100%;
  padding: 60px 0;
  background-color: #29856B;
  color: #FEFBFA;
}
#footer_container {
  max-width: 1370px;
  margin: 0 auto;
}
#logo_footer {
  width: 200px;
}
#logo_footer img {
  filter: brightness(0) invert(1);
}
#footer_container .box{
  float: left;
  width: 23%;
  margin: 0 1%;
}
#footer_container h2 {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 40px;
}
#footer_container b {
  color: #fff;
}
#footer_container p {
  font-size: 15px;
  line-height: 1.5;
}
#footer ul {
  margin: 0;
  padding: 0;
}
#footer ul li{
  list-style: none;
  margin-bottom: 10px ;
}
#footer ul li a{
  color: #fff;
  font-size: 15px;
}
#footer ul li a:hover{
  color: #42B38E;
}
.iso_9001 {

}
.iso_9001 img{
  width: 150px;
}
#copy {
  background-color: #42B38E;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  font-size: 12px;
}
#iconos {
  margin-top: 10px;
}
#iconos img{
  width: 200px;
}
#scroll {
  position: fixed;
  bottom: 0;
  left: 50%;
  text-align: center;
  display: block;
  z-index: 4;
}
#scroll p {
  color: #fff;
  font-size: 14px;
}
#scroll.active {
  display: none;
}
.mouse {
  background: #4e5559 linear-gradient(transparent 0%, transparent 50%, #fff 50%, #fff 100%);
  position: relative;
  width: 40px;
  height: 60px;
  border-radius: 100px;
  background-size: 100% 200%;
  animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
}
.mouse:before, .mouse:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.mouse:before {
  width: 36px;
  height: 54px;
  background-color: #222a30;
  border-radius: 100px;
}
.mouse:after {
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  animation: trackBallSlide 5s linear infinite;
}
@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
 }
  20% {
    background-position: 0% 0%;
 }
  21% {
    background-color: #4e5559;
 }
  29.99% {
    background-color: #fff;
    background-position: 0% 0%;
 }
  30% {
    background-color: #4e5559;
    background-position: 0% 100%;
 }
  50% {
    background-position: 0% 0%;
 }
  51% {
    background-color: #4e5559;
 }
  59% {
    background-color: #fff;
    background-position: 0% 0%;
 }
  60% {
    background-color: #4e5559;
    background-position: 0% 100%;
 }
  80% {
    background-position: 0% 0%;
 }
  81% {
    background-color: #4e5559;
 }
  90%, 100% {
    background-color: #fff;
 }
}
@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
 }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
 }
  14% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
 }
  15%, 19% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
 }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
 }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
 }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
 }
  44% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
 }
  45%, 49% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
 }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
 }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
 }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(5px);
 }
  74% {
    opacity: 0;
    transform: scale(0.4) translateY(40px);
 }
  75%, 79% {
    opacity: 0;
    transform: scale(0.4) translateY(-20px);
 }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-20px);
 }
}
@keyframes nudgeMouse {
  0% {
    transform: translateY(0);
 }
  20% {
    transform: translateY(8px);
 }
  30% {
    transform: translateY(0);
 }
  50% {
    transform: translateY(8px);
 }
  60% {
    transform: translateY(0);
 }
  80% {
    transform: translateY(8px);
 }
  90% {
    transform: translateY(0);
 }
}
@keyframes nudgeText {
  0% {
    transform: translateY(0);
 }
  20% {
    transform: translateY(2px);
 }
  30% {
    transform: translateY(0);
 }
  50% {
    transform: translateY(2px);
 }
  60% {
    transform: translateY(0);
 }
  80% {
    transform: translateY(2px);
 }
  90% {
    transform: translateY(0);
 }
}
@keyframes colorText {
  21% {
    color: #4e5559;
 }
  30% {
    color: #fff;
 }
  51% {
    color: #4e5559;
 }
  60% {
    color: #fff;
 }
  81% {
    color: #4e5559;
 }
  90% {
    color: #fff;
 }
}
.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 550px;
}
.banner.servicios {
  background-image: url("/public/img/intro-service.jpg");
}
.banner.nosotros {
  background-image: url("/public/img/intro-nosotros.jpg");
}
.banner_text {
  max-width: 1370px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}
.banner_text h2 {
  display: inline-block;
  align-self: flex-end;
  color: #fff;
  font-size: 48px;
  text-transform: uppercase;
  padding-bottom: 40px;
}
.banner_text h2 span{
  display: block;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
}
.content_servicios, .content_nosotros, .content_contacto {
  max-width: 1370px;
  margin: 0 auto 50px auto;
}
.content_servicios h1, .content_nosotros h1 {
  color: #233469;
  font-size: 40px;
  font-weight: 400;
  line-height: 0.8;
  margin-bottom: 30px;
}
.content_servicios h1 span {
  font-size: 30px;
  line-height: 0;
}
.content_servicios .content_left, .content_servicios .content_right {
  width: 46%;
  float: left;
  margin: 5% 2%;
}
.content_nosotros ul, .content_servicios ul{
 margin: 0;
 padding: 0;
}
.content_servicios ul li{
  margin: 40px 0 10px 0;
  list-style: none;
  font-size: 25px;
}
.content_nosotros ul li {
  margin: 10px 0;
  list-style: none;
  font-size: 14px;
}
.content_servicios ul li .fa-check, .content_nosotros ul li .fa-check {
  margin-right: 10px;
  color:#233469;
}
.content_servicios .content_left ul ul li{
  list-style: none;
  font-size: 15px;
  margin: 0px 0 10px 30px;
  display: inline-block;
  width: 260px;
}
.content_servicios .content_right ul ul li{
  list-style: none;
  font-size: 15px;
  margin: 0px 0 10px 30px;
}
.content_servicios .content_left h2{
  color: #42B38E;
  font-size: 17px;
  margin-top: 20px;
}
.content_servicios .content_left .svg-inline--fa, .content_servicios .content_right .svg-inline--fa {
  color: #fff;
  background: #233469;
  border-radius: 5px;
  width: 15px;
  height: 15px;
  padding: 5px;
}
.content_nosotros {
  margin-top: 40px;
}
.content_nosotros .box {
  width: 29%;
  margin: 2%;
  float: left;
  text-align: left;
}
.content_nosotros .box h2 {
  margin-bottom: 20px;
  color: #233469;
}
.content_nosotros .box_content .vym {
  font-size: 20px;
}
.content_nosotros .box_content {
  padding: 40px;
  border: solid 10px #f7f7f7;
  height: 240px;
}
#zonafranca {
  position: relative;
}
#zonafranca .content_right {
  position: relative;
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  width: 50%;
  height: 700px;
  float: right;
  overflow: hidden;
}
#zonafranca .content_left {
  background: #ffff;
  position: absolute;
  width: 40%;
  padding: 60px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 3;
}
#zonafranca h2 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}
#zonafranca h2 span{
  font-weight: 600;
}
#zonafranca iframe {
  position: absolute;
  width: 98vw;
  height: 50vw;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content_contacto ul li {
  list-style: none;
  margin-bottom: 10px;
  font-size: 15px;
}
.content_contacto ul {
  margin: 30px 0 0 0;
  padding: 0;
}
.content_contacto ul li .svg-inline--fa{
  color: #233469;
  margin-right: 5px;
}
.content_contacto .content_left h2{
  color: #233469;
  font-size: 20px;
  font-weight: 500;
}
.content_contacto .content_left, .content_contacto .content_right {
  width: 46%;
  margin: 60px 2%;
  float: left;
}
.content_right .right_content {
  padding: 40px;
  border: solid 10px #f7f7f7;
}
.content_right .right_content input[type=text], .content_right .right_content input[type=email] {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 2%;
  width: 96%;
}
.content_right .right_content textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 2%;
  min-height: 100px;
  width: 96%;
}
.content_right .right_content h2 {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 500;
}

.content_right .right_content input[type=submit] {
  border: none;
  background: #233469;
  border:solid 2px #233469;
  color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
}
.content_right .right_content input[type=submit]:hover {
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: #233469;
  font-weight: 600;
}
#map  {
  width: 100%;
  overflow: hidden;
  height: 450px;
}
#map iframe {
  position: relative;
  height: 450px;
  border: none;
}
.content_servicios .content_right h2{
  font-size: 30px;
  color: #233469;
  text-align: center;
  font-weight: 400;
}
.lqb a {
  font-weight: 600;
  margin: 30px 0 10px 0px;
  background: #233469;
  border: solid 1px #233469;
  display: inline-block;
  color: #fff;
  padding: 20px;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
}
.lqb a:hover {
  background: #fff;
  color: #233469;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 800px) {
  nav {
    float: none;
    width: 100%;
    clear: both;
    text-align: center;
  }
  nav ul li {
    font-size: 10px;
  }
  #video {
    display: none;
  }
  #container {
    margin-top: 160px;
  }
  .scroll {
    display: none;
  }
  #servicios {
    margin: 40px auto;

   }
   #servicios .titulo h2 {
    font-size: 30px;
  }
  #servicios .box {
    width: 96%;
    float: none;
  }
  #nosotros .content_left{
    display: none;
  }
  #nosotros .content_right {
    background: #ffff;
    position: relative;
    width: 92%;
    padding: 4%;
    top: 0;
    left: 0;
    transform: none;
    margin: 0 auto;
  }
  #zonafranca .content_right {
    display: none;
  }
  #zonafranca .content_left {
    background: #ffff;
    position: relative;
    width: 92%;
    padding: 4%;
    top: 0;
    left: 0;
    transform: none;
    z-index: 3;
  }
  #footer_container .box {
    float: left;
    width: 92%;
    margin: 4%;
  }
  .banner {
    height: 200px;
  }
  .banner_text h2 {
    font-size: 35px;
    padding: 4%;
  }
  .content_nosotros .box {
    width: 92%;
    margin: 4%;
  }
  .content_servicios .content_left, .content_servicios .content_right {
    width: 92%;
    float: none;
    margin: 4%;
  }
  .content_servicios ul li {
    font-size: 20px;
  }
  .content_contacto .content_left, .content_contacto .content_right {
    width: 92%;
    margin: 4%;
    float: none;
  }
  #logo {
    width: 200px;
    float: none;
    margin: 0 auto;
  }
  .titulo {
    padding: 4%;
  }
  .content_servicios h1, .content_nosotros h1 {
    font-size: 40px;
    margin: 50px 4%;
  }
  .content_servicios, .content_nosotros, .content_contacto {
    margin: 0 auto;
  }
  #nosotros .content_right h2 {
    font-size: 25px;
    padding: 4%;
  }
  #nosotros.home .content_right h2 {
    font-size: 25px;
    padding: 0;
  }
  .content_nosotros .box_content .vym {
    font-size: 18px;
  }
}